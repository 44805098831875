<template>
  <v-container
    v-if="isResponsive"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div>
          <p class="text-title mon-bold">{{ texts.disputes.textTitle }}</p>
        </div>
      </v-col>
      <!-- filters -->
      <v-col cols="12">
        <div class="display-flex align-items-center mb-4">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-4"
            :placeholder="texts.disputes.textSearch"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
          <disputes-filter-layout
            :tab="tab"
            :texts="texts"
          />
        </div>
        <div>
          <v-menu
            ref="bMenuFinal"
            v-model="bMenuFinal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular mb-4"
                readonly
                v-model="startDateTextField"
                clearable
                hide-details
                append-icon="mdi-calendar"
                @click:clear="
                  startDate = null;
                  startDateTextField = null
                  endDate = null;
                "
                :placeholder="texts.disputes.filters.startDate"
                :label="texts.disputes.filters.startDate"
                persistent-hint
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="#C41F79"
              v-model="startDate"
              @change="changeFormatDateStartDate()"
              no-title
              @input="bMenuFinal = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-menu
            ref="bMenuInitial"
            v-model="bMenuInitial"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular mb-4"
                readonly
                v-model="endDateTextField"
                clearable
                hide-details
                append-icon="mdi-calendar"
                @click:clear="endDate = null; endDateTextField = null; "
                :placeholder="texts.disputes.filters.endDate"
                :label="texts.disputes.filters.endDate"
                persistent-hint
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="startDate"
              color="#C41F79"
              v-model="endDate"
              @change="changeFormatDateEndDate()"
              no-title
              @input="bMenuInitial = false"
            ></v-date-picker>
          </v-menu>

        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="container-profile">
          <template>
            <v-tabs
              v-model="tab"
              show-arrows
              background-color="transparent"
            >
              <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
              <v-tab
                class="tabs-profile mon-regular"
                @click="tab1"
              >{{
                texts.disputes.textTab1
              }}</v-tab>
              <v-tab
                class="tabs-profile mon-regular"
                @click="tab2"
              >{{
                texts.disputes.textTab2
              }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <disputes-tab-content-one-layout :texts="texts" />
              </v-tab-item>
              <v-tab-item>
                <disputes-tab-content-two-layout :texts="texts" />
              </v-tab-item>
            </v-tabs-items>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <div>
          <p class="text-title mon-bold">{{ texts.disputes.textTitle }}</p>
        </div>
      </v-col>
      <!-- filters -->
      <v-col
        cols="12"
        md="8"
      >
        <div class="display-flex align-items-center">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :placeholder="texts.disputes.textSearch"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
          <v-menu
            ref="bMenuFinal"
            v-model="bMenuFinal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                color="#000000"
                dense
                class="global-inputs inputs-date mon-regular mr-2"
                readonly
                v-model="startDateTextField"
                clearable
                hide-details
                append-icon="mdi-calendar"
                @click:clear="
                  startDate = null;
                  startDateTextField = null
                  endDate = null;
                "
                :placeholder="texts.disputes.filters.startDate"
                :label="texts.disputes.filters.startDate"
                persistent-hint
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="#C41F79"
              v-model="startDate"
              @change="changeFormatDateStartDate()"
              no-title
              @input="bMenuFinal = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="bMenuInitial"
            v-model="bMenuInitial"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                color="#000000"
                dense
                class="global-inputs inputs-date mon-regular mr-2"
                readonly
                v-model="endDateTextField"
                clearable
                hide-details
                append-icon="mdi-calendar"
                @click:clear="endDate = null; endDateTextField = null; "
                :placeholder="texts.disputes.filters.endDate"
                :label="texts.disputes.filters.endDate"
                persistent-hint
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="startDate"
              color="#C41F79"
              v-model="endDate"
              @change="changeFormatDateEndDate()"
              no-title
              @input="bMenuInitial = false"
            ></v-date-picker>
          </v-menu>
          <disputes-filter-layout
            :tab="tab"
            :texts="texts"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="container-profile">
          <template>
            <v-tabs
              v-model="tab"
              show-arrows
              background-color="transparent"
            >
              <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
              <v-tab
                class="tabs-profile mon-regular"
                @click="tab1"
              >{{
                texts.disputes.textTab1
              }}</v-tab>
              <v-tab
                class="tabs-profile mon-regular"
                @click="tab2"
              >{{
                texts.disputes.textTab2
              }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <disputes-tab-content-one-layout :texts="texts" />
              </v-tab-item>
              <v-tab-item>
                <disputes-tab-content-two-layout :texts="texts" />
              </v-tab-item>
            </v-tabs-items>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DisputesContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      tab: 0,
      bMenuFinal: false,
      bMenuInitial: false,
      startDate: null,
      startDateTextField: null,
      endDate: null,
      endDateTextField: null,
      sSearch: "",
      isResponsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    clearFilters: function () {
      this.sSearch = "";
      this.$store.commit("setSearch", "");
      this.startDate = null;
      this.startDateTextField = null;
      this.$store.commit("setStartDate", null);
      this.endDate = null;
      this.endDateTextField = null;
      this.$store.commit("setEndDate", null);
      this.$store.commit("setDisputeStatus", []);
      this.$store.commit("setDisputePaymentStatus", null);
    },
    tab1: function () {
      this.clearFilters();
    },
    tab2: function () {
      this.clearFilters();
    },
    changeFormatDateStartDate() {
      let aDate = this.startDate.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.startDateTextField = date;
    },
    changeFormatDateEndDate() {
      let aDate = this.endDate.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.endDateTextField = date;
    },
  },
  watch: {
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 300),
    startDate: function () {
      this.$store.commit("setStartDate", this.startDate);
    },
    endDate: function () {
      this.$store.commit("setEndDate", this.endDate);
    },
  },
};
</script>

<style scoped>
.inputs-date {
  width: 145px !important;
  flex: none !important;
}

.button-filter {
  width: 40px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
}

.separator-line-tab {
  background: transparent linear-gradient(268deg, #fffc81 0%, #7eff86 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
</style>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>